import React, { useMemo } from "react"
import Layout from "../components/Layout"
import TourBoxes from "../components/TourBoxes"

export default function Serie({ pageContext, location }) {
  const series = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("series"))
        : [],
    []
  )
  const serie = useMemo(
    () => location?.state?.serie || series[0],
    [location?.state?.serie]
  )

  const tours = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("tours")).filter(
            t => t.serie === serie?.serie
          )
        : [],
    [serie]
  )
  return (
    <Layout pageContext={pageContext}>
      <div
        className="page-banner"
        style={{ backgroundImage: `url(${serie?.banner})` }}
      >
        <div className={`page-banner-content ${pageContext.language}`}>
          <h1>{serie?.serie}</h1>
          <h2>{serie?.subtitle}</h2>
        </div>
      </div>
      <TourBoxes tours={tours} title={serie?.serie} />
    </Layout>
  )
}

export { Head } from "../components/Head"
